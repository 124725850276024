<template>
    <section>
        <vue-headful title="FLPN Aviasolutions - List Waypoints"></vue-headful>
        <h1 class="title">List Waypoints</h1>
      <contenedor>
        <b-table
            paginated
            backend-pagination
            backend-sorting
            :data="pagination.records"
            :loading="pagination.loading"
            :total="pagination.total"
            :per-page="pagination.perPage"
            :default-sort-direction="pagination.defaultSortOrder"
            :default-sort="[pagination.sortField, pagination.sortOrder]"
            @page-change="onPageChange"
            @sort="onSort">
            <template slot-scope="props">
                <b-table-column field="name" label="Name" sortable>
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column field="description" label="Description" sortable>
                    {{ props.row.description }}
                </b-table-column>
                <b-table-column field="hdms_latitude" label="Latitude" sortable>
                    {{ props.row.latitude }}
                </b-table-column>
                <b-table-column field="hdms_longitude" label="Longitude" sortable>
                    {{ props.row.longitude }}
                </b-table-column>
                <b-table-column width="80">
                    <div class="field has-addons">
                        <p class="control">
                            <router-link :to="`/waypoints/${props.row.id}/edit`" class="button is-small is-primary">
                                <b-icon pack="far" icon="edit"></b-icon>
                            </router-link>
                        </p>
                        <p class="control">
                            <button type="button"
                                    class="button is-small is-danger"
                                    v-on:click="onDeleteWaypoint(props.row)">
                                <b-icon pack="far" icon="trash-alt"></b-icon>
                            </button>
                        </p>
                    </div>
                </b-table-column>
            </template>
        </b-table>
        <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
      </contenedor>
    </section>
</template>
<script>
import WaypointService from '../../services/waypoint.service';
import Contenedor from "@/components/Contenedor";

export default {
    name: 'WaypointIndex',
  components: {Contenedor},
  data() {
        return {
            isLoading: false,
            waypointService: new WaypointService(),
            pagination: {
                records: [],
                total: 0,
                loading: false,
                sortField: 'name',
                sortOrder: 'asc',
                defaultSortOrder: 'desc',
                page: 1,
                perPage: 20
            }
        };
    },
    methods: {
        paginate() {
            const params = {
                sort_field: this.pagination.sortField,
                sort_order: this.pagination.sortOrder,
                per_page: this.pagination.perPage,
                page: this.pagination.page
            };
            this.pagination.loading = true;
            this.waypointService.paginate(params).then(
                ({data}) => {
                    this.pagination.records = data.records;
                    this.pagination.total = data.total;
                    this.pagination.loading = false
                },
                () => {
                    this.pagination.records = []
                    this.pagination.total = 0
                    this.pagination.loading = false
                    this.$buefy.toast.open({
                        message: 'An error ocurred while paginating!!!',
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                }
            );
        },
        /*
        * Handle page-change event
        */
        onPageChange(page) {
            this.pagination.page = page;
            this.paginate();
        },
        /*
        * Handle sort event
        */
        onSort(field, order) {
            this.pagination.sortField = field;
            this.pagination.sortOrder = order;
            this.paginate();
        },
        onDeleteWaypoint(waypoint) {
            this.$buefy.dialog.confirm({
                title: 'Deleting Waypoint',
                message: `This will delete the waypoint: <b>${waypoint.name}</b> from the database, are you sure?`,
                cancelText: 'Cancel',
                confirmText: 'Yes, delete',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.isLoading = true;
                    this.waypointService.delete(waypoint.id).then(
                        ({data}) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: data.notificacion,
                                type: 'is-success'
                            });
                            this.paginate();
                        },
                        () => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: 'An error ocurred while deleting the waypoint.',
                                type: 'is-danger'
                            });
                        }
                    );
                }
            })
        }
    },
    mounted() {
        this.paginate();
    }
}
</script>