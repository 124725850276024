import ApiService from "./api.service"

export default class WaypointService extends ApiService {
    path = 'waypoints';

    search(ident, params) {
        return this.http.get(`${this.url}/search/${ident}`, { params: params });
    }

    airways(waypointId) {
        return this.http.get(`${this.url}/${waypointId}/airways`);
    }

    paginate(params) {
        return this.http.get(`${this.url}/paginate`, { params: params } );
    }
}