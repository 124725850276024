<template>
  <div class="columns">
    <div class="column is-12 is-full-mobile fixedleft">
      <div class="card">
        <div class="card-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contenedor"
}
</script>