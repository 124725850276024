import Vue from 'vue'

export default class ApiService {
    path = '';
    get host() {
        return process.env.VUE_APP_HOST;
    }
    get url() {
        return `${this.host}/${this.path}`;
    }
    get http() {
        // The 'http' service can be used globally Vue.http or in a Vue instance this.$http.
        return Vue.http;
    }
    findAll() {
        return this.http.get(`${this.url}`)
    }
    findOne(id, params = null) {
        return this.http.get(`${this.url}/${id}`, { params: params });
    }
    edit(id) {
        return this.http.get(`${this.url}/${id}/edit`);
    }
    create(body) {
        return this.http.post(this.url, body);
    }
    update(id, body) {
        return this.http.put(`${this.url}/${id}`, body);
    }
    delete(id) {
        return this.http.delete(`${this.url}/${id}`);
    }
    save(body) {
        if (body.id) {
            return this.update(body.id, body);
        }
        return this.create(body);
    }
}